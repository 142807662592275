import {bufferTime, catchError, concatMap, debounceTime, filter, map, switchMap, take} from 'rxjs/operators';
import {isNotNullOrUndefined} from 'codelyzer/util/isNotNullOrUndefined';
import {NbAuthJWTToken, NbAuthService, NbAuthToken, NbTokenService} from '@nebular/auth';
import {fromEvent, interval, merge, of as observableOf, Subscription} from 'rxjs';
import {AuthService} from '../service/auth.service';
import {Router} from '@angular/router';
import {Injectable, OnDestroy} from '@angular/core';
import {BnNgIdleService} from 'bn-ng-idle';
import {environment} from '../../../environments/environment';
import * as moment from 'moment';
import {last} from 'rxjs-compat/operator/last';

@Injectable({
  providedIn: 'root',
})
export class TokenUtils {
  activitySubscription: Subscription = null;

  private nbAuthToken: NbAuthToken;

  constructor(
    private authService: AuthService,
    private nbAuthService: NbAuthService,
    // private tokenService: NbTokenService,
    private router: Router,
  ) {
    this.nbAuthService.onTokenChange()
      .subscribe((nbAuthToken) => this.nbAuthToken = nbAuthToken);
  }

  destroy() {
    this.activitySubscription.unsubscribe();
    this.activitySubscription = null;
  }

  refresh(url: string) {
    if (this.nbAuthToken.isValid()) {
      const lastRefresh = moment(parseInt(localStorage.getItem('lastRefresh'), 10));
      const callRefresh = lastRefresh.add(environment.refreshTime, 'seconds').isBefore(moment());

      // if refreshTime done, call refresh api
      if (callRefresh) {
        return this.authService.refresh(this.nbAuthToken.getValue())
          .pipe(switchMap(response => {
              // refresh response OK
              if (isNotNullOrUndefined(response)) {
                console.log('token response: ', response);
                // this.tokenService.set(new NbAuthJWTToken(response, this.strategyName));
                this.nbAuthService.refreshToken(this.nbAuthToken.getOwnerStrategyName(), { token: response });
                localStorage.setItem('lastRefresh', moment().valueOf().toString());
                return observableOf(true);

                // refresh response KO
              } else {
                // this.router.navigate(['/auth/logout', {url: url}]);
                console.log('invalid token');
                this.logout();
                return observableOf(false);
              }
            }),
          );
        // token OK, but no need to call refresh api
      } else {
        console.log('no need refresh');
        return observableOf(true);
      }
    }

    console.error('invalid or expired token');
    this.logout();
    return observableOf(false);
  }

  // refresh(url: string) {
  //   return this.tokenService.get().pipe(
  //     switchMap(nbAuthToken => {
  //       if (nbAuthToken.isValid()) {
  //         const lastRefresh = moment(parseInt(localStorage.getItem('lastRefresh'), 10));
  //         const callRefresh = lastRefresh.add(environment.refreshTime, 'seconds').isBefore(moment());
  //
  //         // if refreshTime done, call refresh api
  //         if (callRefresh) {
  //           return this.authService.refresh(nbAuthToken)
  //             .pipe(switchMap(response => {
  //                 // refresh response OK
  //               if (isNotNullOrUndefined(response)) {
  //                 console.log(response);
  //                 this.tokenService.set(new NbAuthJWTToken(response, this.strategyName));
  //                 localStorage.setItem('lastRefresh', moment().valueOf().toString());
  //
  //                 // set token for next uses
  //                 this.token = response;
  //                 return of(true);
  //
  //                 // refresh response KO
  //               } else {
  //                 // this.router.navigate(['/auth/logout', {url: url}]);
  //                 console.log('invalid token');
  //                 this.logout();
  //                 return of(false);
  //               }
  //             }),
  //           );
  //           // token OK, but no need to call refresh api
  //         } else {
  //           console.log('no need refresh');
  //           return of(true);
  //         }
  //       }
  //
  //       throw new Error('invalid token or expired');
  //     }),
  //
  //     // if invalid token or refresh api return KO
  //     catchError(() => {
  //       // this.router.navigate(['/auth/logout', {url: url}]);
  //       console.log('catcherror');
  //       this.logout();
  //       return of(false);
  //     }),
  //   );
  // }

  startObservingActivity() {
    const userActivity$ = merge(
      fromEvent(document, 'click'),
      fromEvent(document, 'scroll'),
      fromEvent(document, 'mousemove'),
    ).pipe();

    const activityCheckInterval$ = interval(environment.intervalPeriod);

    this.activitySubscription = activityCheckInterval$.pipe(
      map(() => userActivity$.pipe(
        bufferTime(environment.bufferTime),
        take(1),
      )),
    ).subscribe(events => {
      events.subscribe(event => {
        if (event.length > 0) {
          this.refresh(this.router.url).subscribe(value => console.log(value));
        }
        if (event.length <= 0) {
          // this.nbAuthService.getToken().subscribe(token => {
          //   if (!token.isValid()) {
          //     console.log('new inactivity');
          //     this.logout(this.router.url);
          //   }
          // });

          if (!this.nbAuthToken.isValid()) {
            this.logout(this.router.url);
          }
        }
      });
    });
  }

  logout(url?: string) {
    let options = {};

    if (url) {
      options = {
        ...options,
        url: url,
      };
    }

    this.nbAuthService.logout(environment.security.strategyName);
    localStorage.clear();
    this.router.navigate(['/auth/login', options]);
  }

  public getToken(): string {
    return this.nbAuthToken.getValue();
  }
}
