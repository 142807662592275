<div class="header-container">
  <div class="logo-container">
    <a *ngIf="!hideMenu; else hideToggle"
       href="#"
       class="sidebar-toggle"
       matRipple
       [matRippleUnbounded]="true"
       [matRippleCentered]="true"
       (click)="toggleSidebar()"
    >
      <nb-icon [icon]="(materialTheme$ | async) ? 'menu-outline' : 'menu-2-outline'"></nb-icon>
    </a>

    <ng-template #hideToggle>
      <nb-icon>&nbsp;</nb-icon>
    </ng-template>

    <a class="logo" href="#" (click)="navigateHome()">
      <img src="assets/images/logo-meteotrentino.png" alt="Meteo Trentino">
    </a>
  </div>

  <!--<nb-select
    status="primary"
    matRipple
    [selected]="currentTheme"
    (selectedChange)="changeTheme($event)"
  >
    <nb-option
      *ngFor="let theme of themes"
      [value]="theme.value"
      matRipple
    >{{ theme.name }}</nb-option>
  </nb-select>-->
</div>

<div class="header-container">
  <nb-actions size="small">
<!--
    <nb-action class="control-item">
      <nb-search
        type="rotate-layout"
        matRipple
        [matRippleUnbounded]="true"
        [matRippleCentered]="true"
      ></nb-search></nb-action>
    <nb-action
      class="control-item"
      icon="email-outline"
      matRipple
      [matRippleUnbounded]="true"
      [matRippleCentered]="true"
    ></nb-action>
    <nb-action
      class="control-item"
      icon="bell-outline"
      matRipple
      [matRippleUnbounded]="true"
      [matRippleCentered]="true"
    ></nb-action>
-->
    <nb-action
      class="logged-user-action"
      matRipple
      [matRippleUnbounded]="true"
      [matRippleCentered]="true"
      [nbContextMenu]="userMenu"
    >
      <i class="fa fa-solid fa-user fa-2x"></i>
    </nb-action>
  </nb-actions>
</div>
