import {Observable, of} from 'rxjs';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {ServiceUtils} from '../utils/service.utils';
import {switchMap} from 'rxjs/operators';
import {TokenUtils} from '../utils/token.utils';
import {inject, Inject, Injectable} from '@angular/core';

export class GenericReadOnlyService<T> {
  private tokenUtils: TokenUtils = inject(TokenUtils);

  constructor(private httpClient: HttpClient, private serviceName: string) {}

  findAll(): Observable<T[]> {
    return this.httpClient.get<T[]>(ServiceUtils.getApiUrl(this.serviceName));
  }
  findById(id: any): Observable<T> {
    return this.httpClient.get<T>(ServiceUtils.getApiUrl(this.serviceName, [id]));
  }
}
