import {Injectable} from '@angular/core';
import {ProbabilisticLegendModel} from '../model/probabilistic-legend.model';
import {GenericReadOnlyService} from './generic-readonly.service';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ProbabilisticLegendService extends GenericReadOnlyService<ProbabilisticLegendModel> {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'probabilistic-legend');
  }
}
