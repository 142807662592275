import {Injectable} from '@angular/core';
import {GenericReadOnlyService} from './generic-readonly.service';
import {ProbabilisticEventModel} from '../model/probabilistic-event.model';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ProbabilisticEventService extends GenericReadOnlyService<ProbabilisticEventModel> {

  constructor(httpClient: HttpClient) {
    super(httpClient, 'probabilistic-event');
  }
}
