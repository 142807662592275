import {Injectable} from '@angular/core';
import * as _moment from 'moment';
import {Moment} from 'moment';

@Injectable()
export class DateUtils {
  constructor() {
    _moment.updateLocale('it', {
      week: {
        // Set the First day of week to Sunday
        dow: 1,
        doy: null,
      },
    });
  }

  static getNextMonthStartAndEndOfWeeks(): DateRange[] {
    let nextMonth = _moment().add(1, 'months').startOf('month');

    const weeks: DateRange[] = [];

    for (let i = 1; i <= 4; i++) {
      weeks.push({
        start: nextMonth.clone().startOf('week'),
        end: nextMonth.clone().endOf('week'),
      });

      nextMonth = nextMonth.add(1, 'week');
    }

    return weeks;
  }

  static getNextDays(count: number): DateRange[] {
    const today = _moment();

    const days: DateRange[] = [];

    for (let i = 1; i <= count; i++) {
      today.add(1, 'days');
      days.push({
        start: today.clone(),
        end: today.clone(),
      });
    }

    return days;
  }
}

export class DateRange {
  start: Moment;
  end: Moment;
}
