import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ServiceUtils} from '../utils/service.utils';
import {NbAuthService} from '@nebular/auth';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private httpClient: HttpClient, private tokenService: NbAuthService) {
  }

  login(code: string): Observable<any> {
    const headers = new HttpHeaders().set('authorization', code);
    return this.httpClient.post<any>(ServiceUtils.getSecurityUrl().login, {}, { headers, observe: 'response' })
      .pipe(
        map(response => {
          return response.body.token;
        }),
      );
  }

  ping(token: string): Observable<any> {
    const headers = new HttpHeaders().set('authorization', token);
    return this.httpClient.get<any>(ServiceUtils.getSecurityUrl().ping, { headers, observe: 'response'});
  }

  refresh(token: string): Observable<string> {
    const headers = new HttpHeaders().set('authorization', token);
    return this.httpClient.get<any>(ServiceUtils.getSecurityUrl().refresh, { headers, observe: 'response' })
      .pipe(
        map(response => {
          return response.headers.get('authorization');
        }),
      );
  }
}
