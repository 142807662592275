import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  NbAuthJWTToken,
  NbAuthModule,
  NbAuthOAuth2JWTToken,
  NbOAuth2AuthStrategy,
  NbOAuth2GrantType,
  NbOAuth2ResponseType, NbPasswordAuthStrategy,
} from '@nebular/auth';
import {NbRoleProvider} from '@nebular/security';
import {of as observableOf} from 'rxjs';

import {throwIfAlreadyLoaded} from '../../@core/module-import-guard';
import {AnalyticsService, DateUtils, LayoutService, SeoService, StateService} from '../../@core/utils';
import {AuthService} from '../../@core/service/auth.service';
import {TokenUtils} from '../../@core/utils/token.utils';
import {HttpRequest} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ServiceUtils} from '../../@core/utils/service.utils';

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf('guest');
  }
}

export const AUTH_PROVIDERS = [
  ...NbAuthModule.forRoot({

    strategies: [
      // oidc strategy
      NbOAuth2AuthStrategy.setup({
        name: 'oidc',
        clientId: 'c_7764324244c54af69af0119480ee7fd3',
        clientSecret: '',
        redirect: {
          success: '/',
          failure: '/',
        },
        authorize: {
          endpoint: 'https://aac.cloud-qual.tndigit.it/oauth/authorize',
          responseType: NbOAuth2ResponseType.CODE,
          scope: 'openid profile email', // Adjust scopes as needed
          redirectUri: `${window.location.origin}/auth/authorization`,
        },
        token: {
          endpoint: 'https://aac.cloud-qual.tndigit.it/oauth/token',
          grantType: NbOAuth2GrantType.AUTHORIZATION_CODE,
          class: NbAuthOAuth2JWTToken,
        },
      }),

      // custom strategy
      NbPasswordAuthStrategy.setup({
        name: 'jwt',
        token: {
          class: NbAuthJWTToken,
          key: 'token', // this parameter tells where to look for the token
          // key: 'authenticated', // this parameter tells where to look for the token
        },
        baseEndpoint: '',

        login: {
          endpoint: ServiceUtils.getSecurityUrl().login,
          method: 'post',
        },

        logout: {
          endpoint: ServiceUtils.getSecurityUrl().logout,
          method: 'post',
          redirect: {
            success: '/auth/login',
          },
        },

        refreshToken: {
          endpoint: ServiceUtils.getSecurityUrl().refresh,
          method: 'post',
          redirect: {
            failure: '/auth/login',
          },
        },
      }),
    ],

    forms: {
      login: {
        redirectDelay: 100,
        strategy: 'jwt',
        rememberMe: false,
        showMessage: {
          success: true,
          error: true,
        },
        redirect: {
          success: '/',
          failure: null,
        },
      },
    },
  }).providers,

  /*
  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: '*',
      },
      user: {
        parent: 'guest',
        create: '*',
        edit: '*',
        remove: '*',
      },
    },
  }).providers,
  */

  {provide: NbRoleProvider, useClass: NbSimpleRoleProvider},

  AnalyticsService,
  LayoutService,
  SeoService,
  StateService,
  DateUtils,

  /* Services*/
  AuthService,
  TokenUtils,
];

export function nbNoOpInterceptorFilter(request: HttpRequest<any>): boolean {
// skip interceptor for url that NOT point to api
  return !request.url.startsWith(`${environment.api.domain}${environment.api.context}`);
}

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
})
export class AuthProviderModule {
  constructor(@Optional() @SkipSelf() parentModule: AuthProviderModule) {
    throwIfAlreadyLoaded(parentModule, 'AuthProviderModule');
  }

  static forRoot(): ModuleWithProviders<AuthProviderModule> {
    return {
      ngModule: AuthProviderModule,
      providers: [
        ...AUTH_PROVIDERS,
      ],
    };
  }
}
